var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollGuide',{ref:"scrollGuide"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],class:[
      'ResourceList',
      _vm.active && 'active',
      _vm.active && 'pageScrollTarget',
      _vm.enableScroll && 'scrollY' ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item && _vm.item.caption),expression:"item && item.caption"}],staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.item && _vm.item.caption)}}),_vm._v(" "),_c('div',{ref:"items",staticClass:"items"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
          'item',
          _vm.isSelected(item) && 'selected',
          'talkScriptType-' + item.talkScriptType ]},[_c('div',{staticClass:"relation"},[_c('div',{staticClass:"relation-line"})]),_vm._v(" "),_c('div',{staticClass:"itemContent",attrs:{"item-debug":JSON.stringify(item)},on:{"click":function($event){return _vm.open(item)}}},[_c('span',[_c('span',{staticClass:"itemIcon"}),_vm._v(_vm._s(item.text))]),_vm._v(" "),_c('font-awesome-icon',{attrs:{"icon":"chevron-right"}})],1)])}),0),_vm._v(" "),_c('ResourceListFooter',{attrs:{"currentValue":_vm.currentValue}})],1),_vm._v(" "),_c('div',{ref:"anchor",staticClass:"anchor"},[_c('span',{staticClass:"anchorBar"}),_vm._v(" "),_c('span',{staticClass:"anchorBox"})])])}
var staticRenderFns = []

export { render, staticRenderFns }