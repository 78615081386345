<template>
  <div
    class="resolveFeedback"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div>
      <div class="resolveButtonArea">
        <div class="feedbackGuide">
          <div class="text">
            {{ feedbackGuideText }}
          </div>
        </div>
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div class="chat_conductor">
      <div class="content">
        <div class="title">
          <img src="../resource/net/operator.png" />
          <div class="text">解決しなかった場合は、オペレーターがチャットでお答えします。</div>
        </div>
        <div class="desc">
          <div class="desc_area">
            <div class="bold">サポート時間</div>
            <div>平日：9:00～23:00（18:00～23:00は<span class="highlight bold">投信／NISAのみ</span>）</div>
            <div>
              <span>土日：9:00～17:00（<span class="highlight bold">投信／NISAのみ</span>）</span>
            </div>
            <div>※祝日・年末年始を除く</div>
            <div>※FX、先物、法人、相続・贈与のご案内はできません</div>
          </div>
        </div>
        <div v-if="showChatConductor" class="button_area">
          <a
            class="chat_button"
            @click="toChat"
            data-ratid="vis_faq_answer_operatorchat"
            data-ratevent="click"
            data-ratparam="all"
          >
            <span>オペレーターとチャットする</span>
          </a>
        </div>
        <div v-else class="invalid_button_area">
          <div class="invalid_button">
            <img class="information_icon" src="../resource/common/information.png" />
            <span class="text">
              ただ今の時間はチャットできません。
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="lastMessageArea">
      <div class="buttonWrapper reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

const ENQUETE_TYPE = {
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
};

const ENQUETE_ACTION = {
  SEARCH_FAILED: 'searchFailed',
  SEARCH_NO_SCRIPT: 'searchNoScript',
  FEEDBACK_RESOLVED: 'feedbackResolved',
  FEEDBACK_UNRESOLVED: 'feedbackUnresolved',
};

@Component({})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin,
  ProductTicketMixin
) {
  resolveMessage = Const.ASK_FEEDBACK_CHOICES.RESOLVED;
  unsolvedMessage = Const.ASK_FEEDBACK_CHOICES.UNRESOLVED;
  goToTopLabel = Const.GO_TO_TOP_LABEL;
  feedbackGuideText = Const.FEEDBACK_GUIDE_TEXT;

  @AsyncComputed()
  showChatConductor() {
    const now = new Date();
    return this.isOutsideBusinessHoursForChatConductor(now);
  }
  
  isOutsideBusinessHoursForChatConductor(datetime) {
    const year = datetime.getFullYear();
    const month = datetime.getMonth();
    const date = datetime.getDate();
    const day = datetime.getDay(); // 0: 日曜日, 1: 月曜日, ..., 6: 土曜日
    const startTime = Const.CHAT_CONDUCTOR_HIDDEN_HOURS[day].START_TIME;
    const endTime = Const.CHAT_CONDUCTOR_HIDDEN_HOURS[day].END_TIME;
    if (!startTime || !endTime) return true;
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    const startDateTime = new Date(year, month, date, startHours, startMinutes);
    const endDateTime = new Date(year, month, date, endHours, endMinutes);
    return startDateTime <= datetime && datetime < endDateTime;
  }

  async reset(e) {
    eventBus.$emit('resetWithScrollTop');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async resolved() {
    this.feedbackResult = 'resolved';

    eventBus.$emit('openEnquete', {
      type: ENQUETE_TYPE.RESOLVED,
      action: ENQUETE_ACTION.RESOLVED,
      item: this.currentValue
    });

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'resolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async unresolved() {
    this.feedbackResult = 'unresolved';

    eventBus.$emit('openEnquete', {
      type: ENQUETE_TYPE.UNRESOLVED,
      action: ENQUETE_ACTION.UNRESOLVED,
      item: this.currentValue
    });

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'unresolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  generateTicket(item, startTime, query, tagActiveSet, feedback) {
    const commonTicket = {
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'answered',
      items: item.items,
      status_feedback: 'done',
      feedback: feedback,
      status_enquete: 'open',
      ...this.customizedTicket
    };

    return newTicket;
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    const commonTicket = {
      origin: this.$store.state.product.ticketOrigin,
      page_type: this.$store.state.product.ticketPageType,
      initial_url: this.$store.state.product.ticketInitialUrl,
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    const newTicket = {
      ...commonTicket,
      status: 'open',
    };

    return newTicket;
  }
  toChat() {
    if (this.$store.state.user.isSP) {
      window.open(
        Const.CUSTOM.OPERATOR_LINK_SP,
        '_blank',
      );
    } else {
      window.open(
        Const.CUSTOM.OPERATOR_LINK_PC,
        '_blank',
      );
    };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
@import '../style/utility/embedHtml';

$mainColor: #BF0000;
$hoverColor: #E80000;
$disabledColor: #f7f7f7;

.resolveFeedback {
  padding-top: 36px;
  position: relative;
}

.resolveButtonArea {
  padding: 18px 0;
  text-align: center;

  .mobile & {
    padding: 0px;
  }
}

.feedbackGuide {
  .text {
    font-size: 14px;
    text-align: center;
  }
}

.buttonWrapper {
  text-align: center;
  padding-top: 12px;

  .resolveButton {
    display: inline-block;
    margin: 6px;
    width: 200px;
    padding: 8px 0;
    font-size: 16px;
    font-weight: 700;
    color: $mainColor;
    text-decoration: none;
    text-align: center;
    border: 1px solid $mainColor;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;

    .mobile & {
      margin: 0px;
      width: 100%;
      margin-bottom: 8px;
    }

    &:hover {
      color: $hoverColor;
      border: 1px solid $hoverColor;
      .mobile & {
        color: $mainColor;
        border: 1px solid $mainColor;
      }
    }

    &.disabled {
      pointer-events: none;
      background-color: $disabledColor;
    }
  }

  &.reset {
    .resolveButton {
      color: #fff;
      background-color: $mainColor;
      border: none;
      padding: 9px 0;
      width: 240px;

      .mobile & {
        width: 100%;
      }

      &:hover {
        color: #fff;
        border: none;
        background-color: $hoverColor;
        .mobile & {
          background-color: $mainColor;
        }
      }
    }
  }
}

.chat_conductor {

  .ifa & {
    display: none;
  }
  .partner & {
    display: none;
  }

  padding: 32px 0;
  .mobile & {
    padding: 8px 0 4px 0;
  }

  .content {
    padding: 24px;
    background-color: #F7F7F7;
    .mobile & {
      padding: 16px;
    }


    .title, .desc, .button_area, .invalid_button_area, .invalid_button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .title {
      padding-bottom: 4px;
      .mobile & {
        padding-bottom: 16px;
      }

      .icon {
        width: 48px;
        height: 40px;
      }
      .text {
        padding-left: 16px;
        font-size: 14px;
        font-weight: 700;
      }
    }

    .desc {
      padding-top: 10px;
      padding-bottom: 16px;
      .desc_area {
        color: #686868;
        font-size: 14px;
        .bold {
          font-weight: bold;
          color:#333;
        }
        .highlight {
          color: #ff0000;
        }
      }
    }

    .button_area {
      a {
        width: 418px;
        display: inline-block;
        padding: 8px 0;
        text-align: center;
        color: $mainColor;
        line-height: 21px;
        font-size: 14px;
        font-weight: 700;
        background-color: #fff;
        border: 1px solid $mainColor;
        border-radius: 4px;

        .mobile & {
          line-height: 24px;
        }

        span::after {
          content: url(../resource/net/click.svg);
          width: 16px;
          height: 16px;
          position: relative;
          top: 3px;
          margin-left: 4px;
        }

        &:hover {
          color: $hoverColor;
          border: 1px solid $hoverColor;
          .mobile & {
            color: $mainColor;
            border: 1px solid $mainColor;
          }

          span::after {
            content: url(../resource/net/click_light.svg);
            width: 16px;
            height: 16px;
            position: relative;
            top: 3px;
            margin-left: 4px;

            .mobile & {
              content: url(../resource/net/click.svg);
            }
          }
        }
      }
    }

    .invalid_button_area {
      width: 100%;
      .invalid_button {
        background-color: #EBF7FE;
        border: 1px solid #959595;
        border-radius: 4px;
        width: 418px;
        span {
          margin-left: 3px;
          text-align: center;
          display: inline-block;
          padding: 8px 0;
          line-height: 21px;
          font-size: 14px;
          font-weight: 700;
          color: #006497;
          font-size: 14px;
        }
        .information_icon {
          width: 16px;
        }
      }
    }
  }
}

.lastMessageArea {
  .message {
    padding: 24px 12px;
    text-align: center;
    white-space: pre-wrap;
    background-color: #f2f2f2;
    border-radius: 5px;
    font-size: 16px;
  }
}
</style>
