var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ScrollGuide',{ref:"scrollGuide"},[_c('div',{directives:[{name:"scroll",rawName:"v-scroll",value:(_vm.onScroll),expression:"onScroll"}],class:[
      'ResourceList',
      _vm.active && 'active',
      _vm.active && 'pageScrollTarget',
      _vm.enableScroll && 'scrollY',
      _vm.currentValue && _vm.currentValue.viewType === 'result' && 'result',
      _vm.currentValue && _vm.currentValue.viewType === 'scenario' && 'scenario' ]},[_c('div',{ref:"scrollWrapper",staticClass:"scrollWrapper"},[(_vm.currentValue.viewType !== 'talkScript')?_c('div',{staticClass:"head"},[_c('div',{staticClass:"button"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.backToSearch()}}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../resource/common/next_black.svg")}})]),_vm._v(" "),_c('div',{staticClass:"text"},[_vm._v("\n              "+_vm._s(_vm.backToSearchButtonText)+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"title faq"},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"img"})]),_vm._v(" "),_c('div',{staticClass:"text"},[_c('div',{staticClass:"ancestors"},_vm._l((_vm.lastScript.ancesters),function(ancester,index){return _c('span',{key:index,staticClass:"ancester"},[_vm._v("\n                "+_vm._s(ancester.text)+"\n                "),(index < _vm.lastScript.ancesters.length - 1)?_c('span',[_vm._v("＞")]):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"faq"},[_vm._v("\n              "+_vm._s(_vm.generateTitle(_vm.lastScript.text))+"\n            ")])])]),_vm._v(" "),_c('div',{staticClass:"border"})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"description"},[_c('div',{staticClass:"icon"},[_c('div',{staticClass:"img"})]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.item && _vm.item.caption),expression:"item && item.caption"}],staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.item && _vm.item.caption)}})]),_vm._v(" "),_c('div',{ref:"items",staticClass:"items"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:[
            'item',
            _vm.isSelected(item) && 'selected',
            'talkScriptType-' + item.talkScriptType,
            _vm.firstIndex && 'first' ]},[_c('div',{staticClass:"relation"},[_c('div',{staticClass:"relation-line"})]),_vm._v(" "),_c('div',{staticClass:"itemWrapper"},[_c('div',{class:['itemContent', index === 0 && 'firstItem'],attrs:{"item-debug":JSON.stringify(item)},on:{"click":function($event){$event.preventDefault();return _vm.openHandler(item)}}},[_c('span',[_vm._v(_vm._s(item.text))]),_vm._v(" "),_c('div',{staticClass:"img"})])])])}),0),_vm._v(" "),_c('ResourceListFooter',{attrs:{"currentValue":_vm.currentValue,"lastScript":_vm.lastScript},on:{"scrollBottom":_vm.scrollBottom}})],1)]),_vm._v(" "),_c('div',{ref:"anchor",staticClass:"anchor"},[_c('span',{staticClass:"anchorBar"}),_vm._v(" "),_c('span',{staticClass:"anchorBox"})])])}
var staticRenderFns = []

export { render, staticRenderFns }